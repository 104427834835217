import XHR from '@/request/http'
const $h = new XHR()
// url
const url = {
    lists:'/admin/v1/dict/page',
    update:'/admin/v1/dict/update',
    create:'/admin/v1/dict/create',
    del:'/admin/v1/dict/del',
    expert_dict:'/admin/v1/dict/expert/titles',
    teacher_dict:'/admin/v1/dict/teacher/titles',
    type_lists:'/admin/v1/dict/type/list',
    type_create:'/admin/v1/dict/type/create',
    type_update:'/admin/v1/dict/type/update',
    type_del:'/admin/v1/dict/type/del',
    rule_type:'/admin/v1/dict/rules/type',//指标项类型
    model:'/admin/v1/dict/model/type',//模型列表
}


export async function lists(data)
{
    return $h.request(url.lists,data,'GET')
}

export async function create(data)
{
    return $h.request(url.create,data,'POST')
}

export async function update(data)
{
    return $h.request(url.update,data,'POST')
}

export async function del(data)
{
    return $h.request(url.del,data,'POST')
}

export async function expert_dict(data)
{
    return $h.request(url.expert_dict,data,'GET')
}

export async function teacher_dict(data)
{
    return $h.request(url.teacher_dict,data,'GET')
}

export async function type_lists(data)
{
    return $h.request(url.type_lists,data,'GET')
}

export async function type_create(data)
{
    return $h.request(url.type_create,data,'POST')
}

export async function type_update(data)
{
    return $h.request(url.type_update,data,'POST')
}


export async function type_del(data)
{
    return $h.request(url.type_del,data,'POST')
}

export async function rule_type(data)
{
    return $h.request(url.rule_type,data,'GET')
}
export async function model(data)
{
    let res = await $h.request(url.model,data,'GET')
    if(res.code==0) {
        //处理
        let _d = [];
        res.data && res.data.forEach(item => {
            _d.push({label:item,value:item})
        });
        res.data2 = _d
    }
    return res;
}